@import "_variables.css";

* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    vertical-align: top;
    font-size: 14px;
    line-height: 1;
    outline: 0px;
    /*outline: 1px solid black;*/
}

body {
    /* text-align: center; */
    overflow-x: hidden;
    overflow-y: auto;
}

ul,
ol {
    list-style: none;
    font-size: 0px;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
}

ul li,
ol li {
    display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font: inherit;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
}

a {
    display: inline-block;
    text-decoration: none;
    color: black;
}

.wrap {
    /* display: inline-block; */
    margin: 0px auto;
    width: 1200px;
}

.pc {
    display: inline-block !important;
}

.mobile {
    display: none !important;
}

.pcimg {}

.moimg {
    display: none;
}

.flexBox {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}

table {
    border-collapse: collapse;
}

caption {
    display: none;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="search"],
input[type="image"],
input[type="tel"],
input[type="submit"],
button,
select,
textarea {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

button {
    background: none;
    border: 0;
}

@media only screen and (min-width:100px) and (max-width:1300px) {}

@media only screen and (min-width:100px) and (max-width:1200px) {

    .wrap {
        width: 100%;
        padding: 0px 15px;
    }

    .pc {
        display: none !important;
    }

    .mobile {
        display: inherit !important;
    }

}

@media only screen and (min-width:100px) and (max-width:1000px) {}

@media only screen and (min-width:100px) and (max-width:800px) {}

@media only screen and (min-width:100px) and (max-width:600px) {
    .pcimg {
        display: none;
    }

    .moimg {
        display: block;
    }
}

html,
button {
    font-family: 'GmarketSansMedium';
}